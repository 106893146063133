@mixin respond($breakpoint) {
  @if ($breakpoint == large) {
    @media (max-width: 1100px) {
      @content;
    }
  }
  @if ($breakpoint == medium) {
    @media (max-width: 900px) {
      @content;
    }
  }
  @if ($breakpoint == small) {
    @media (max-width: 800px) {
      @content;
    }
  }
}

:root {
  --main-purple: #3500d3;
  --main-white: #ffffff;
  --main-black: #000000;
  --main-purple-secondary: #7a4eff;
}

* {
  box-sizing: border-box;
  font-family: "Montserrat", sans-serif;
}
a {
  text-decoration: none;
}

body {
  margin: 0;
  padding: 0;

  @include respond(medium) {
    overflow-x: hidden;
  }
}

.navBar {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  min-height: 4rem;
  font-weight: bolder;
  padding: 0.2% 12.5%;
  // position: sticky;
  // top: 0px;
  // z-index: 2;
  background-color: var(--main-white);

  @include respond(large) {
    margin-bottom: 5rem;
    overflow-x: hidden;
    padding: 0.2% 12%;
  }
  .logoImg {
    height: 25px;
  }

  .navUl {
    list-style: none;
    display: flex;
    flex-direction: row;

    @include respond(medium) {
      position: absolute;
      right: 0;
      top: 6vh;
      height: 100vh;
      // left: 0;
      background-color: var(--main-white);
      flex-direction: column;
      align-items: center;
      width: 100%;
      z-index: 1;
      justify-content: space-evenly;
      transform: translateX(100%);
      transition: transform 0.4s ease-in;
    }
  }
  .nav-active {
    transform: translateX(0%);
    @include respond(small) {
      transform: translateX(-5%);
    }
  }
  .burgerMenu {
    display: none;

    @include respond(medium) {
      display: block;
      cursor: pointer;
    }

    .toggle {
      transform: rotate(-45deg) translate(-5px, 6px);
    }
    .toggle2 {
      opacity: 0;
    }

    .toggle3 {
      transform: rotate(45deg) translate(-5px, -6px);
    }
  }
  .burgerMenu div {
    width: 25px;
    height: 3px;
    background-color: var(--main-black);
    transition: transform 0.4s ease;

    margin: 5px;
  }

  .navLinks {
    padding: 0 30px;
    cursor: pointer;
    align-self: center;
    text-decoration: none;

    &.resume {
      border: solid 1px var(--main-purple);
      color: var(--main-purple);
      padding: 9px 25px;
      border-radius: 5px;
      margin-left: 1rem;
    }
    .linkHash {
      text-decoration: none;
      color: black;
    }
  }
}

.heroSection,
.aboutMeSection,
.projectsSection,
.ContactSection {
  width: 80%;
  margin: auto;
}

.heroSection {
  overflow-x: hidden;
  height: 90vh;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0 2% 0 5%;
  @include respond(large) {
    flex-direction: column-reverse;
    justify-content: center;
    align-items: center;
    padding: 0 2% 0 1%;
  }

  .content {
    display: flex;
    flex-direction: column;
    @include respond(large) {
      margin-top: 5rem;
    }
    .h4text {
      color: var(--main-purple);
    }
    .h1text {
      margin-top: 0;
      font-size: 2.5rem;
      @include respond(small) {
        font-size: 2rem;
      }
    }
    .h4text,
    .h2text {
      letter-spacing: 0.5px;
      margin-top: 0;
    }
    .h2text {
      font-size: 1.8rem;
      @include respond(small) {
        font-size: 1.35rem;
      }
    }
    .h5text {
      width: 55%;
      line-height: 2;
      letter-spacing: 1px;
      margin-top: 10px;
      font-size: 0.9rem;
      @include respond(small) {
        width: 100%;
      }
    }
  }

  .svgImgg {
    margin-left: auto;
    width: 22rem;
    @include respond(large) {
      margin-left: 0;
    }
    @include respond(small) {
      width: 19rem;
    }
  }
}

.aboutMeSection {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 7% 2% 10% 5%;
  @include respond(large) {
    padding: 7rem 2% 10% 1.5%;
  }

  .contentAndImage {
    display: flex;
    flex-direction: row;
    @include respond(large) {
      flex-direction: column-reverse;
      margin-top: 2rem;
    }
  }
  .h2textabout {
    margin-top: -3rem;
  }

  .aboutMeContent {
    display: flex;
    flex-direction: column;
    width: 60%;
    @include respond(large) {
      width: 100%;
    }
  }
  .imgContainer {
    margin-left: auto;

    margin-top: 2rem;
    position: relative;
    height: 350px;
    width: 280px;
    border: solid 2px var(--main-purple-secondary);
    border-radius: 5px;
    @include respond(large) {
      margin-left: 0;
      align-self: center;
      margin-bottom: 2rem;
    }
    @include respond(small) {
      height: 300px;
      width: 240px;
    }
    .imgProfile {
      height: 350px;
      width: 285px;
      position: absolute;
      right: 8px;
      top: -11px;
      border-radius: 5px;
      @include respond(small) {
        height: 300px;
        width: 240px;
      }
    }
  }
  .spanText {
    font-weight: bold;
    margin-top: 2rem;
    line-height: 2;
    letter-spacing: 0.4px;
    &.tech {
      margin-top: 5rem;
    }
  }
  .backEndFrontEndContainer {
    display: flex;
    flex-direction: row;
    margin-top: 4rem;
    justify-content: space-between;
    @include respond(large) {
      flex-direction: column;
    }
    .frontEndBox {
      box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2),
        0 6px 20px 0 rgba(0, 0, 0, 0.19);
      padding: 2rem 2.5rem;
      display: flex;
      flex-direction: column;
      border-radius: 5px;
      @include respond(large) {
        padding: 1rem;
        margin-bottom: 3rem;
      }
      .spanTitle {
        align-self: center;
        text-transform: uppercase;
        font-weight: bold;
      }
    }
  }
}
.frontEndTools,
.backEndBox {
  display: flex;
  flex-direction: row;
  margin-top: 1rem;

  .fnTools2 {
    margin-left: 3rem;
    @include respond(medium) {
      margin-left: 0.1rem;
    }
  }
  .fnTool {
    display: flex;
    align-items: center;
    margin: 1.5rem 0.5rem;
    .triangleRight {
      color: var(--main-purple);
    }
  }
}
.techName {
  margin-left: 0.8rem;
  text-transform: uppercase;
  font-size: 15px;
  @include respond(medium) {
    font-size: 11px;
    margin-left: 0.2rem;
  }
}

.projectsSection {
  padding: 5% 2% 0 5%;
  @include respond(large) {
    padding: 7rem 2% 10% 1.5%;
  }
  display: flex;
  flex-direction: column;

  .Project1Container,
  .Project3Container {
    display: flex;
    flex-direction: column;
  }
  .nameLabelLine {
    margin: 2rem 0;
    display: flex;
    flex-direction: row;
    align-items: center;

    .vl {
      border-bottom: 1px solid gray;
      margin-top: 5px;
      height: inherit;
      width: 35%;
      @include respond(large) {
        width: 50%;
      }
    }
  }

  .h2textabout {
    margin-top: -3rem;
  }
  .projectContentAndImage {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    @include respond(large) {
      flex-direction: column-reverse;
    }

    .hirestationImg {
      height: 15rem;
      border-radius: 5px;
      margin-left: auto;
      @include respond(large) {
        margin-left: 0;
        align-self: center;
        margin: 2rem 0 4rem 0;
      }
      @include respond(small) {
        height: 10rem;
      }
    }
  }
  .linksContainer {
    display: flex;
    align-items: center;
    margin: 2rem 0;
  }
}

.projContent {
  width: 50%;
  line-height: 1.5;
  letter-spacing: 0.5px;
  @include respond(large) {
    width: 100%;
  }
}
.projectLabel {
  font-weight: bold;
  margin-right: 1rem;
}

.Project2Container {
  display: flex;
  flex-direction: column;
  margin-top: 5rem;
  margin-bottom: 7.5rem;
  .nameLabelLine2 {
    margin: 2rem 0;
    display: flex;
    flex-direction: row;
    justify-content: end;
    .vl {
      border-bottom: 1px solid gray;
      margin-bottom: 5px;
      width: 35%;
      margin-right: 1rem;
      @include respond(large) {
        width: 50%;
      }
      @include respond(small) {
        width: 40%;
      }
    }
  }
}
.projectContentAndImage1 {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  @include respond(large) {
    flex-direction: column;
  }

  .baathcheethImg {
    height: 15rem;
    border-radius: 5px;
    margin-right: auto;
    padding-right: 2rem;
    @include respond(large) {
      margin-right: 0;
      align-self: center;
      margin: 2rem 0 4rem 0;
    }
    @include respond(small) {
      height: 10rem;
    }
  }
}

.ContactSection {
  padding: 5% 2% 10% 5%;
  display: flex;
  flex-direction: column;
  @include respond(large) {
    padding: 5rem 2% 10% 1.5%;
  }
  .formContainer {
    margin-top: 2rem;
  }
  .nameAndEmailSection {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    @include respond(medium) {
      flex-direction: column;
      width: 100%;
    }
    .nameContainer {
      display: flex;
      flex-direction: column;
      width: 90%;
      @include respond(medium) {
        width: 100%;
      }
    }
    .emailContainer {
      display: flex;
      flex-direction: column;
      width: 90%;
      margin-left: 5rem;
      @include respond(medium) {
        margin-left: 0;
        margin-top: 3rem;
        width: 100%;
      }
    }

    .inputForm {
      box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2),
        0 6px 20px 0 rgba(0, 0, 0, 0.19);

      padding: 0.6rem 0.4rem;
      width: 100%;
      border-width: 1px;
      outline: none;
    }
  }
  .label {
    margin-bottom: 2rem;
    font-weight: bold;
  }
  .MessageContainer {
    display: flex;
    flex-direction: column;
    margin-top: 4rem;

    #messageArea {
      width: 46.5%;
      box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2),
        0 6px 20px 0 rgba(0, 0, 0, 0.19);

      height: 7rem;
      resize: none;
      outline: none;
      padding: 0.5rem 0.4rem;
      @include respond(medium) {
        width: 100%;
      }
    }
  }
  .submitBtn {
    background-color: var(--main-purple-secondary);
    border: none;
    color: var(--main-white);
    padding: 0.8rem;
    margin-top: 4rem;
    width: 10rem;
    border-radius: 3px;
    cursor: pointer;

    @include respond(medium) {
      width: 100%;
    }
  }
}
.footerSection {
  width: 80%;
  margin: auto;
  padding: 3% 1.5% 4% 3.7%;

  .footerContainer {
    display: flex;
    flex-direction: column;
    @include respond(medium) {
      align-items: center;
    }
  }
  .socialMediaAndQuote {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 1rem;

    @include respond(medium) {
      flex-direction: column;
    }
    .icons {
      margin-left: 1rem;
    }
    .socialMedia {
      @include respond(medium) {
        margin: 2rem 0;
      }
    }
  }
}
.spSpan {
  @include respond(small) {
    text-align: center;
  }
}
